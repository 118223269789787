import locale from "localization";
import { Text } from "components/commons";
import styles from "./manual-bank-form.module.scss";

const ManualBanking = ({ title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Text header bolder>
          {title || locale.cashInProcessInstruction}
        </Text>
      </div>
      <div className={styles.description}>
        <Text>{locale.makeYourManualDeposit}</Text>
      </div>
      <div className={styles.indent}>
        <div className={styles.title}>
          <Text header bolder>
            {locale.accountName}
          </Text>
        </div>
        <div className={styles.description}>
          <Text>{locale.locqOPC}</Text>
        </div>
        <div className={styles.title}>
          <Text header bolder>
            {locale.bankNameAcctNumber}
          </Text>
        </div>
        <div className={styles.description}>
          <span className={styles.acctnum}>{locale.bdo_short}</span>
          <span>{locale.bdoAcctNum}</span>
        </div>
        <div className={styles.description}>
          <span className={styles.acctnum}>{locale.ubp_short}</span>
          <span>{locale.ubpAcctNum}</span>
        </div>
        <div className={styles.description}>
          <span className={styles.bpi}>{locale.bpi_short}</span>
          <span>{locale.bpiAcctNum}</span>
        </div>
      </div>
      <div className={styles.description}>
        <Text>
          {
            <locale.Populate
              text={locale.sendUsProof}
              items={[<strong>{"plb-support@locq.com"}</strong>]}
            />
          }
        </Text>
      </div>
      <div className={styles.indent}>
        <div className={styles.title}>
          <Text header bolder>
            {locale.bankDepositAndOnlineBankTransferViaInstapay}
          </Text>
        </div>
        <div className={styles.description}>
          <Text>{locale.twoToThreeHoursWithinBusinessDay}</Text>
        </div>
        <div className={styles.title}>
          <Text header bolder>
            {locale.PESONet}
          </Text>
        </div>
        <div className={styles.description}>
          <span className={styles.acctnum}>{locale.PESONetBeforeCutOff}</span>
        </div>
        <div className={styles.description}>
          <span className={styles.acctnum}>{locale.PESONetAfterCutOff}</span>
        </div>
        <div className={styles.title}>
          <Text header bolder>
            {locale.checkDepositClearing}
          </Text>
        </div>
        <div className={styles.description}>
          <span className={styles.acctnum}>
            {<locale.Populate text={locale.IfLCK} items={[<strong>{"LCK"}</strong>]} />}
          </span>
        </div>
        <div className={styles.description}>
          <span className={styles.acctnum}>
            {<locale.Populate text={locale.IfLCKL} items={[<strong>{"LCK L"}</strong>]} />}
          </span>
        </div>
      </div>
      <div className={styles.description}>
        <Text>{locale.onceWeReceivedTheProof}</Text>
      </div>
      <div className={styles.description}>
        <Text>{locale.onceVerifiedOrCleared}</Text>
      </div>
      <div className={styles.description}>
        <Text>
          {
            <locale.Populate
              text={locale.pleaseNote50deduction}
              items={[<strong>{"Php 50.00"}</strong>]}
            />
          }
        </Text>
      </div>
    </div>
  );
};

export default ManualBanking;
